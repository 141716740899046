import React from 'react';
import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';

// Load Stripe publishable key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const pricingPlans = [
  {
    name: 'Basic Plan',
    price: '$10/month',
    features: ['Feature 1', 'Feature 2', 'Feature 3'],
    priceId: process.env.REACT_APP_STRIPE_BASIC_PLAN_ID, // Use environment variable for Stripe Price ID
  },
  {
    name: 'Pro Plan',
    price: '$20/month',
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    priceId: process.env.REACT_APP_STRIPE_PRO_PLAN_ID, // Use environment variable for Stripe Price ID
  },
  {
    name: 'Premium Plan',
    price: '$30/month',
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
    priceId: process.env.REACT_APP_STRIPE_PREMIUM_PLAN_ID, // Use environment variable for Stripe Price ID
  },
];

const PaymentPage = () => {
  const handlePayment = async (plan) => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: String(plan.priceId), quantity: 1 }],
      mode: 'subscription',
      successUrl: window.location.origin + '/success',
      cancelUrl: window.location.origin + '/cancel',
    });

    if (error) {
      console.error('Error redirecting to Stripe:', error);
    }
  };

  return (
    <Box p={8}>
      <Heading as="h1" mb={8}>
        Pricing Plans
      </Heading>
      <VStack spacing={8}>
        {pricingPlans.map((plan) => (
          <Box
            key={plan.name}
            p={8}
            borderWidth={1}
            borderRadius="lg"
            w="100%"
            maxW="md"
            textAlign="center"
          >
            <Heading as="h2" size="lg" mb={4}>
              {plan.name}
            </Heading>
            <Text fontSize="xl" mb={4}>
              {plan.price}
            </Text>
            <VStack spacing={2} mb={4}>
              {plan.features.map((feature, index) => (
                <Text key={index}>{feature}</Text>
              ))}
            </VStack>
            <Button colorScheme="teal" onClick={() => handlePayment(plan)}>
              Choose {plan.name}
            </Button>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default PaymentPage;
