import React, { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
  Spinner,
  Input,
  CheckboxGroup,
  Grid,
  Image,
  Progress,
  Center,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { FitnessLevel, FitnessGoal } from '../utils/enums.js';
import { generateMealPlan } from '../services/api.js';

const MealSuggestions = ({ userProfile }) => {
  const parsePropValue = (value) => {
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }
    return value;
  };

  const [preferences, setPreferences] = useState({
    duration: 30,
    intensity: 'moderate',
    age: userProfile?.age ?? '',
    weight: userProfile?.weight ?? '',
    height: userProfile?.height ?? '',
    fitnessLevel: userProfile?.fitnessLevel ?? FitnessLevel.INTERMEDIATE,
    gender: userProfile?.gender ?? 'not specified',
    goal: userProfile?.goals?.[0] ?? FitnessGoal.GENERAL_FITNESS,
    availableEquipment: [],
    workoutDuration: 60,
    workoutFrequency: 3,
    dietaryPreferences: parsePropValue(userProfile?.mealPreferences) ?? ['balanced'],
    healthGoals: parsePropValue(userProfile?.goals) ?? [FitnessGoal.GENERAL_FITNESS],
  });
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPreferences((prev) => ({ ...prev, [name]: value }));
  };

  const handleAllergiesChange = (allergy) => {
    setPreferences((prev) => ({
      ...prev,
      allergies: prev.allergies.includes(allergy)
        ? prev.allergies.filter((a) => a !== allergy)
        : [...prev.allergies, allergy],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (preferences.healthGoals.length === 0) {
      toast({
        title: 'Missing Information',
        description: 'Please select at least one health goal.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    setError(null);
    setSuggestions([]);
    try {
      const userInputs = {
        userId : localStorage.getItem('userId'),
        duration: preferences.duration,
        intensity: preferences.intensity,
        age: preferences.age || '',
        fitnessLevel: preferences.fitnessLevel,
        weight: preferences.weight || '',
        height: preferences.height || '',
        gender: preferences.gender,
        goal: preferences.goal,
        availableEquipment: preferences.availableEquipment,
        workoutDuration: preferences.workoutDuration,
        workoutFrequency: preferences.workoutFrequency,
        dietaryPreferences: preferences.dietaryPreferences,
        healthGoals: preferences.healthGoals
      };
      console.log('Sending meal plan request with inputs:', userInputs);

      const data = await generateMealPlan(userInputs);

      if (Array.isArray(data) && data.length > 0) {
        setSuggestions(data);
        toast({
          title: 'Success',
          description: 'Meal suggestions retrieved successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        setError('No meal suggestions found. Try adjusting your preferences.');
      }
    } catch (error) {
      console.error('Error fetching meal suggestions:', error);
      setError('Failed to fetch meal suggestions. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to fetch meal suggestions. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access
        // You might want to redirect to login page here
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveMeal = useCallback(async (mealId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/save-meal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({ mealId }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      toast({
        title: 'Meal Saved',
        description: 'The meal has been saved to your favorites.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving meal:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('jwtToken');
        toast({
          title: 'Authentication Error',
          description: 'Your session has expired. Please log in again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        // Redirect to login page or trigger re-authentication
      } else {
        toast({
          title: 'Error',
          description: 'Failed to save the meal. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [toast]);
  return (
    <Center>
      <Box p={4} maxWidth="800px" width="100%">
        <Heading mb={4}>Your Daily Meal Plan</Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Duration (days)</FormLabel>
              <Input type="number" name="duration" value={preferences.duration} onChange={handleInputChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Intensity</FormLabel>
              <Select name="intensity" value={preferences.intensity} onChange={handleInputChange} placeholder="Select intensity">
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Fitness Level</FormLabel>
              <Select name="fitnessLevel" value={preferences.fitnessLevel} onChange={handleInputChange} placeholder="Select fitness level">
                {Object.entries(FitnessLevel).map(([key, value]) => (
                  <option key={key} value={value}>
                    {key.charAt(0) + key.slice(1).toLowerCase()}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Goal</FormLabel>
              <Select name="goal" value={preferences.goal} onChange={handleInputChange} placeholder="Select fitness goal">
                {Object.entries(FitnessGoal).map(([key, value]) => (
                  <option key={key} value={value}>
                    {key.charAt(0) + key.slice(1).toLowerCase().replace('_', ' ')}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Dietary Preferences</FormLabel>
              <CheckboxGroup colorScheme="blue" value={preferences.dietaryPreferences} onChange={(value) => setPreferences(prev => ({ ...prev, dietaryPreferences: value }))}>
                <Stack spacing={2} direction="row">
                  <Checkbox value="vegetarian">Vegetarian</Checkbox>
                  <Checkbox value="vegan">Vegan</Checkbox>
                  <Checkbox value="paleo">Paleo</Checkbox>
                  <Checkbox value="keto">Keto</Checkbox>
                </Stack>
              </CheckboxGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Health Goals</FormLabel>
              <CheckboxGroup colorScheme="blue" value={preferences.healthGoals} onChange={(value) => setPreferences(prev => ({ ...prev, healthGoals: value }))}>
                <Stack spacing={2} direction="row">
                  <Checkbox value="weightLoss">Weight Loss</Checkbox>
                  <Checkbox value="muscleGain">Muscle Gain</Checkbox>
                  <Checkbox value="heartHealth">Heart Health</Checkbox>
                  <Checkbox value="energyBoost">Energy Boost</Checkbox>
                </Stack>
              </CheckboxGroup>
            </FormControl>
            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
              Generate Meal Plan
            </Button>
          </VStack>
        </form>

        {isLoading ? (
          <Box mt={8} textAlign="center">
            <Spinner size="xl" />
            <Text mt={2}>Creating your personalized meal plan...</Text>
          </Box>
        ) : error ? (
          <Box mt={8} textAlign="center" color="red.500">
            <Text>{error}</Text>
          </Box>
        ) : (
          <Box mt={8}>
            <Heading size="md" mb={4}>Your Meal Plans</Heading>
            {suggestions.length > 0 ? (
              <>
                <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                  {suggestions.map((meal, index) => (
                    <Box key={meal.id} borderWidth={1} borderRadius="lg" p={4} boxShadow="md">
                      <Image src={`/placeholder-meal-${index + 1}.jpg`} alt={meal.mealName} borderRadius="md" mb={3} />
                      <Heading size="sm" mb={2}>{meal.mealName}</Heading>
                      <Text fontSize="sm" mb={2}>{meal.ingredients}</Text>
                      <Text fontSize="xs" mb={2}>Calories: {meal.calories}, Protein: {meal.protein}g, Carbs: {meal.carbohydrates}g, Fats: {meal.fats}g</Text>
                      <Progress value={(index + 1) * 20} colorScheme="green" mb={2} />
                      <Text fontSize="xs" mb={2}>Progress: {(index + 1) * 20}%</Text>
                      <Button size="sm" colorScheme="green" onClick={() => handleSaveMeal(meal.id)} width="100%">
                        Save to Favorites
                      </Button>
                    </Box>
                  ))}
                </Grid>
                <Box mt={6}>
                  <Heading size="sm" mb={2}>Overall Progress</Heading>
                  <Progress value={30} colorScheme="green" mb={2} />
                  <Text fontSize="sm">You've completed 30% of your meal plan. Keep it up!</Text>
                </Box>
                <Box mt={4}>
                  <Button leftIcon={<ArrowBackIcon />} mr={2}>Previous Day</Button>
                  <Button rightIcon={<ArrowForwardIcon />}>Next Day</Button>
                </Box>
              </>
            ) : (
              <Text>No meal plan available. Generate your personalized meal plan to get started!</Text>
            )}
          </Box>
        )}
      </Box>
    </Center>
  );
};

export default MealSuggestions;
