import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Image,
  Progress,
  useToast,
  Grid,
  Badge,
} from '@chakra-ui/react';
import { Calendar } from 'lucide-react';
import { getExerciseImage, calculateStreak } from '../utils/workoutHelpers';

const EnhancedWorkoutPlanner = ({ workoutPlans, userProfile, onEditPlan, onDeletePlan }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentDay, setCurrentDay] = useState(1);
  const [streak, setStreak] = useState(0);
  const toast = useToast();

  useEffect(() => {
    if (workoutPlans.length > 0) {
      setSelectedPlan(workoutPlans[0]);
    }
    // Calculate streak
    setStreak(calculateStreak(workoutPlans));
  }, [workoutPlans]);

  const handleCompletePlan = () => {
    // Mark the current day as complete
    // This is a placeholder. You'd need to implement the actual logic to mark a day as complete
    toast({
      title: "Workout Completed!",
      description: "Great job on completing today's workout!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    // Update streak
    setStreak(streak + 1);
  };

  if (!selectedPlan) return <Text>No workout plans available.</Text>;

  return (
    <VStack spacing={8} align="stretch">
      <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
        <HStack justifyContent="space-between">
          <VStack align="start">
            <Text fontSize="2xl" fontWeight="bold">{selectedPlan.name}</Text>
            <Badge colorScheme="green">Day {currentDay}</Badge>
          </VStack>
          <VStack align="end">
            <HStack>
              <Calendar size={24} />
              <Text fontWeight="bold">{streak} Day Streak!</Text>
            </HStack>
            <Progress value={(currentDay / selectedPlan.duration) * 100} w="200px" />
          </VStack>
        </HStack>
      </Box>

      <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6}>
        {selectedPlan.workoutPlan[currentDay - 1].exercises.map((exercise, index) => (
          <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="md">
            <Image src={getExerciseImage(exercise.name)} alt={exercise.name} mb={3} />
            <Text fontWeight="bold">{exercise.name}</Text>
            <Text>{exercise.sets} sets x {exercise.reps} reps</Text>
          </Box>
        ))}
      </Grid>

      <HStack justifyContent="space-between">
        <Button onClick={() => setCurrentDay(Math.max(1, currentDay - 1))}>Previous Day</Button>
        <Button colorScheme="green" onClick={handleCompletePlan}>Complete Workout</Button>
        <Button onClick={() => setCurrentDay(Math.min(selectedPlan.duration, currentDay + 1))}>Next Day</Button>
      </HStack>

      <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
        <Text fontSize="xl" fontWeight="bold" mb={3}>Your Progress</Text>
        <Progress value={(currentDay / selectedPlan.duration) * 100} mb={3} />
        <Text>{currentDay} days completed out of {selectedPlan.duration}</Text>
      </Box>

      <HStack justifyContent="space-between">
        <Button colorScheme="blue" onClick={() => onEditPlan(selectedPlan)}>Edit Plan</Button>
        <Button colorScheme="red" onClick={() => onDeletePlan(selectedPlan.id)}>Delete Plan</Button>
      </HStack>
    </VStack>
  );
};

export default EnhancedWorkoutPlanner;
