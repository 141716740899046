import React from 'react';
import { Box, Heading, Text, VStack, Container, Button, useColorModeValue } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const transactionId = location.state?.transactionId;

  const handleExploreFeatures = () => {
    navigate('/');  // Navigate to home page or features page
  };

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const headingColor = useColorModeValue('teal.600', 'teal.300');
  const boxBgColor = useColorModeValue('teal.50', 'teal.900');
  const boxBorderColor = useColorModeValue('teal.300', 'teal.600');
  const transactionIdColor = useColorModeValue('teal.700', 'teal.200');

  return (
    <Box bg={bgColor} minHeight="100vh" py={6}>
      <Container maxW="container.md">
        <VStack spacing={4} align="center">
          <Box textAlign="center" width="100%">
            <Heading as="h1" size="xl" mb={3} color={headingColor}>
              Subscription Successful!
            </Heading>
            <Text fontSize="lg" mb={3} color={textColor}>
              You have successfully subscribed to our service.
            </Text>
            <Box
              bg={boxBgColor}
              borderRadius="md"
              p={5}
              borderWidth={2}
              borderColor={boxBorderColor}
              mb={5}
              boxShadow="md"
            >
              <Text fontSize="xl" fontWeight="bold" color={transactionIdColor}>
                Transaction ID:
                <Text as="span" color={textColor} ml={2} fontSize="lg">
                  {transactionId || 'Not available'}
                </Text>
              </Text>
            </Box>
            <Button
              colorScheme="teal"
              size="lg"
              onClick={handleExploreFeatures}
              mb={3}
            >
              Explore Features
            </Button>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default ConfirmationPage;
