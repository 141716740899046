import React, { useState, useEffect } from 'react';
import { Box, Heading, FormControl, FormLabel, Input, Select, Button, VStack, useToast, Checkbox, Stack, Text } from '@chakra-ui/react';
import apiService from '../services/apiService.js';
import { FitnessGoal, FitnessLevel } from '../utils/enums.js';

const dietaryPreferencesOptions = [
  "VEGETARIAN",
  "VEGAN",
  "PALEO",
  "KETO",
  "PESCATARIAN",
  "GLUTEN_FREE",
  "DAIRY_FREE",
  "HALAL",
  "KOSHER"
];

const UserProfile = () => {
  const [profile, setProfile] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    age: '',
    sex: '',
    weight: '',
    height: '',
    fitnessLevel: '',
    dietaryPreferences: [],
    goal: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateProfileMessage, setShowCreateProfileMessage] = useState(false);
  const toast = useToast();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    const userId = localStorage.getItem('userId');
    setIsLoading(true);
    try {
      const response = await apiService.makeRequest('get', `/user-profile/${userId}`);
      setProfile(response);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      if (error.response && error.response.status === 404) {
        setShowCreateProfileMessage(true); // Show message to create profile
      } else {
        toast({
          title: 'Error',
          description: 'An unexpected error occurred while fetching your profile.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: Array.isArray(prevProfile[name])
        ? (checked
          ? [...prevProfile[name], value]
          : prevProfile[name].filter(item => item !== value))
        : (checked ? [value] : [])
    }));
  };

  const handleSubmit = async (e) => {
    const userId = localStorage.getItem('userId');
    e.preventDefault();
    setIsLoading(true);
    try {
      const updatedProfile = {
        ...profile,
        userId: userId, // Include userId in the updatedProfile object
        fitnessLevel: profile.fitnessLevel, // Ensure fitnessLevel is sent as enum value
      };
      await apiService.makeRequest('post', `/user-profile`, updatedProfile);
      toast({
        title: 'Success',
        description: 'Profile created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setShowCreateProfileMessage(false); // Hide message after profile creation
    } catch (error) {
      console.error('Error creating user profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to create profile',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box maxWidth="500px" margin="auto" padding={4}>
      <Heading as="h1" size="xl" marginBottom={6}>User Profile</Heading>
      
      {/* Conditionally display the message to create a profile */}
      {showCreateProfileMessage && (
        <Text color="red.500" fontWeight="bold" marginBottom={4}>
          No profile found. Please fill out the form below to create your profile.
        </Text>
      )}

      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          {!showCreateProfileMessage && (
            <FormControl>
              <FormLabel>UserId</FormLabel>
              <Input name="userId" value={profile.userId} readOnly />
            </FormControl>
          )}
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input name="firstName" value={profile.firstName} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input name="lastName" value={profile.lastName} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Age</FormLabel>
            <Input name="age" type="number" value={profile.age} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Sex</FormLabel>
            <Select name="sex" value={profile.sex} onChange={handleInputChange}>
              <option value="">Select...</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Weight (kg)</FormLabel>
            <Input name="weight" type="number" value={profile.weight} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Height (cm)</FormLabel>
            <Input name="height" type="number" value={profile.height} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Fitness Level</FormLabel>
            <Select name="fitnessLevel" value={profile.fitnessLevel} onChange={handleInputChange}>
              <option value="">Select...</option>
              {Object.entries(FitnessLevel).map(([key, value]) => (
                <option key={key} value={value}>
                  {key.charAt(0) + key.slice(1).toLowerCase()}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Dietary Preferences</FormLabel>
            <Stack spacing={2} direction="column">
              {dietaryPreferencesOptions.map((option) => (
                <Checkbox
                  key={option}
                  name="dietaryPreferences"
                  value={option}
                  isChecked={Array.isArray(profile.dietaryPreferences) && profile.dietaryPreferences.includes(option)}
                  onChange={handleCheckboxChange}
                >
                  {option.charAt(0) + option.slice(1).toLowerCase().replace('_', ' ')}
                </Checkbox>
              ))}
            </Stack>
          </FormControl>
          <FormControl>
            <FormLabel>Goal</FormLabel>
            <Select name="goal" value={profile.goal} onChange={handleInputChange}>
              <option value="">Select...</option>
              {Object.entries(FitnessGoal).map(([key, value]) => (
                <option key={key} value={value}>
                  {key.charAt(0) + key.slice(1).toLowerCase().replace('_', ' ')}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" colorScheme="blue" isLoading={isLoading}>
            {showCreateProfileMessage ? 'Create Profile' : 'Update Profile'}
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default UserProfile;
