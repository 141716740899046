import axios from 'axios';
import authService from './authService.js';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Token set in request header:', token);
    } else {
      console.log('No token available for request');
    }

    config.headers['Content-Type'] = 'application/json';

    return config;
  },
  (error) => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

export const generateMealPlan = async (userInputs) => {
  try {
    const token = authService.getToken();
    console.log('Token used for meal plan generation:', token);
    const response = await api.post('/api/nutritionalinfo/meal-suggestions', userInputs, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Authentication failed. Please log in again.');
      authService.logout();
      // You might want to redirect to login page here
    }
    console.error('Error generating meal plan:', error);
    throw error;
  }
};

export default api;
