import React, { useState } from "react";
import { Box, Text, Button, Flex, VStack, HStack, Icon, Spinner } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PricingPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const plans = [
    {
      name: "Free Plan",
      price: "$0 per month",
      planId: "free_plan_id",
      features: [
        "Basic Symptom Checker",
        "Limited Medication Management",
        "General Nutritional Guidance",
        "Basic Chatbot Assistance",
        "Access to Free Content Library",
      ],
    },
    {
      name: "Basic Plan",
      price: "$9.99 per month",
      planId: process.env.REACT_APP_STRIPE_BASIC_PLAN_ID,
      features: [
        "Enhanced Symptom Analysis",
        "Expanded Medication Reminders",
        "Personalized Meal Plans (Basic)",
        "Unlimited Chatbot Assistance",
        "Access to Free Content Library",
      ],
    },
    {
      name: "Premium Plan",
      price: "$19.99 per month",
      planId: "price_1PJsNAHhowKCIq32OrUSB9is",
      features: [
        "All Basic Plan Features",
        "Telemedicine Consultations (Limited)",
        "Advanced Nutritional Guidance",
        "Fitness Tracking Integration",
        "Mental Health Support Resources",
        "Access to Premium Content Library",
      ],
    },
  ];

  const handleSubscribe = async (plan) => {
    setSelectedPlan(plan);
    setIsLoading(true);
    try {
      const token = localStorage.getItem("jwtToken");
      const userId = localStorage.getItem("userId");
      const amountInCents = Math.round(parseFloat(plan.price.slice(1, -10)) * 100);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/payment/initialize`,
        {
          planId: plan.planId,
          userId: userId,
          amount: amountInCents,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.error("Error during subscription process:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = (paymentResult) => {
    navigate("/confirmation", { state: { transactionId: paymentResult.paymentIntent.id } });
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }
  return (
    <Box p={4} mb="80px"> {/* Add margin-bottom here */}
      <Text fontSize="3xl" fontWeight="bold" mb={8} textAlign="center">
        Pricing Plans
      </Text>
      <Flex direction="row" justify="center" align="stretch" wrap="wrap">
        {plans.map((plan, index) => (
          <Box key={index} borderWidth="1px" borderRadius="lg" p={6} m={3} width="30%" minWidth="300px">
            <VStack spacing={4} align="stretch" height="100%">
              <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                {plan.name}
              </Text>
              <Text fontSize="xl" fontWeight="medium" textAlign="center">
                {plan.price}
              </Text>
              <VStack spacing={2} align="start" flex={1}>
                {plan.features.map((feature, idx) => (
                  <HStack key={idx}>
                    <Icon as={CheckIcon} color="green.500" />
                    <Text>{feature}</Text>
                  </HStack>
                ))}
              </VStack>
              <Button
                colorScheme="teal"
                size="lg"
                onClick={() => handleSubscribe(plan)}
              >
                Subscribe to {plan.name}
              </Button>
            </VStack>
          </Box>
        ))}
      </Flex>
      {selectedPlan && clientSecret && (
        <Box
          mt={8}
          mb={8}
          p={6}
          borderWidth="1px"
          borderRadius="lg"
          borderColor="teal.200"
          boxShadow="md"
          bg="white"
          color="gray.800"
          maxWidth="800px"
          mx="auto"
        >
          <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign="center">
            Enter Payment Details for {selectedPlan.name}
          </Text>
          <Text fontSize="md" mb={4} textAlign="center">
            You've selected the {selectedPlan.name}. Please enter your card details below to complete your subscription.
          </Text>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm plan={selectedPlan} clientSecret={clientSecret} onPaymentSuccess={handlePaymentSuccess} />
          </Elements>
        </Box>
      )}
    </Box>
  );
};

const CheckoutForm = ({ plan, clientSecret, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (paymentMethodError) {
      console.error("Error creating payment method:", paymentMethodError);
      setIsLoading(false);
      return;
    }

    const paymentIntentId = clientSecret.split('_secret')[0];

    try {
      const token = localStorage.getItem("jwtToken");
      const userId = localStorage.getItem("userId");

      const confirmPaymentRequest = {
        userId: userId,
        paymentMethodId: paymentMethod.id,
        paymentIntentId: paymentIntentId,
        priceId: plan.planId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/payment/confirm-payment`,
        confirmPaymentRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      onPaymentSuccess({ paymentIntent: { id: paymentIntentId } });
    } catch (confirmationError) {
      console.error("Error confirming payment:", confirmationError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={4}>
        <CardElement options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}/>
      </Box>
      <Button 
        type="submit" 
        colorScheme="teal" 
        size="lg" 
        width="100%"
        disabled={!stripe || isLoading}
      >
        {isLoading ? <Spinner size="sm" /> : `Pay ${plan.price} for ${plan.name}`}
      </Button>
    </form>
  );
};

export default PricingPage;