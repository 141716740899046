import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Select,
  CheckboxGroup,
  Checkbox,
  Spinner,
  Image,
  Progress,
  Grid,
  Badge
} from '@chakra-ui/react';
import { Calendar } from 'lucide-react';
import { fetchUserWorkoutPlans } from '../services/apiService.js';
import { useAuth } from '../context/AuthContext.js';
import { getExerciseImage, calculateStreak } from '../utils/workoutHelpers';
import EnhancedWorkoutPlanner from '../components/EnhancedWorkoutPlanner';

// Log the backend URL from environment variables
console.log('REACT_APP_BACKEND_URL:', process.env.REACT_APP_BACKEND_URL);

const WorkoutPlanner = () => {
  const { userId, getToken } = useAuth();
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [userWorkoutPlans, setUserWorkoutPlans] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPlan, setNewPlan] = useState({
    name: '',
    goal: '',
    duration: 0,
    intensity: '',
    age: 0,
    fitnessLevel: '',
    weight: 0,
    height: 0,
    gender: '',
    availableEquipment: [],
    workoutDuration: 0,
    workoutFrequency: 0
  });
  const [editingPlan, setEditingPlan] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

// Fetch user-specific workout plans
const fetchUserWorkoutPlansLocal = async () => {
  if (!userId) {
    console.error('User ID not found');
    toast({
      title: 'Error',
      description: 'User ID not found. Please log in again.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    return;
  }

  console.log('Fetching workout plans for userId:', userId);
  setLoading(true);

  try {
    const data = await fetchUserWorkoutPlans(userId);
    console.log('Workout plans fetched successfully:', data);
    setUserWorkoutPlans(data);
    setWorkoutPlans(data); // Update both state variables
    return data;
  } catch (error) {
    console.error('Error fetching user workout plans:', error);
    if (error.response) {
      if (error.response.status === 404) {
        toast({
          title: 'No Workout Plans',
          description: 'No workout plans found for this user.',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error',
          description: 'Failed to fetch workout plans. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  } finally {
    setLoading(false);
  }
};

  // Fetch user profile and workout plans from the backend API
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const profileResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user-profile/workout-nutrition/${userId}`, {
          headers: {
            'Authorization': `Bearer ${getToken()}`
          }
        });
        const profileData = await profileResponse.json();
        setUserProfile(profileData);

        const plansData = await fetchUserWorkoutPlans(userId);
        setWorkoutPlans(plansData);
        setUserWorkoutPlans(plansData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
        toast({
          title: 'Error fetching data',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, getToken, toast, fetchUserWorkoutPlans]);

  // Removed redundant useEffect hook

  if (loading) return <Spinner />;
  if (error) return <Text color="red.500">{error}</Text>;

  // Handle form submission for new workout plans
  const handleCreatePlan = async (e) => {
    e.preventDefault();
    const formattedPlan = {
      ...newPlan,
      duration: parseInt(newPlan.duration, 10),
      workoutDuration: parseInt(newPlan.workoutDuration, 10),
      workoutFrequency: parseInt(newPlan.workoutFrequency, 10),
      age: parseInt(newPlan.age, 10),
      weight: parseFloat(newPlan.weight),
      height: parseFloat(newPlan.height),
      availableEquipment: Array.isArray(newPlan.availableEquipment) ? newPlan.availableEquipment : [],
      goal: newPlan.goal || "WEIGHT_LOSS",
      fitnessLevel: newPlan.fitnessLevel || "BEGINNER",
      experienceLevel: newPlan.experienceLevel || "BEGINNER",
      dietaryPreferences: newPlan.dietaryPreferences || ["VEGETARIAN"],
    };

    console.log('Form submitted with formatted plan:', formattedPlan);
    const token = getToken();
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/workout-plans/generate-plan`;
    console.log('Sending request to:', url);

    try {
      console.log('Sending request with token:', token ? `${token.substring(0, 10)}...` : 'No token');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          userId,
          ...formattedPlan
        }),
      });

      console.log('Response status:', response.status);
      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        throw new Error(responseData.message || `HTTP error! status: ${response.status}`);
      }

      setWorkoutPlans([...workoutPlans, responseData]);
      setNewPlan({
        name: '', goal: '', duration: 0, intensity: '', age: 0,
        fitnessLevel: '', weight: 0, height: 0, gender: '',
        availableEquipment: [],
        workoutDuration: 0, workoutFrequency: 0
      }); // Reset form
      toast({
        title: 'Workout Plan Created',
        description: "A new workout plan has been generated successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error generating workout plan:', error);
      console.error('Error details:', error.response ? error.response.data : 'No response data');
      toast({
        title: 'Error Generating Workout Plan',
        description: `Failed to create workout plan: ${error.message}. Please try again or contact support if the issue persists.`,
        status: 'error',
        duration: 7000,
        isClosable: true,
      });
    }
  };

  // Handle workout plan deletion
  const handleDeletePlan = (planId) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/workout-plans/${planId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getToken()}`
      }
    })
      .then(() => {
        setWorkoutPlans(workoutPlans.filter(plan => plan.id !== planId));
        toast({
          title: 'Workout Plan Deleted',
          description: "The workout plan has been successfully deleted.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        console.error('Error deleting workout plan:', error);
        toast({
          title: 'Error deleting workout plan',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  // Handle opening the edit modal
  const handleEditPlanOpen = (plan) => {
    setEditingPlan(plan);
    onOpen();
  };

  // Handle workout plan update
  const handleUpdatePlan = () => {
    const token = getToken();
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/workout-plans/${editingPlan.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(editingPlan),
    })
      .then(response => response.json())
      .then(data => {
        setWorkoutPlans(workoutPlans.map(plan => plan.id === data.id ? data : plan));
        onClose(); // Close the modal
        toast({
          title: 'Workout Plan Updated',
          description: "The workout plan has been successfully updated.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        console.error('Error updating workout plan:', error);
        toast({
          title: 'Error updating workout plan',
          description: error.message || 'An unexpected error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <VStack spacing={8}>
      {userProfile && (
        <Box w="full" p={5} shadow="md" borderWidth="1px">
          <Heading size="md" mb={4}>User Profile (Read-only)</Heading>
          <Text><strong>Age:</strong> {userProfile.age}</Text>
          <Text><strong>Gender:</strong> {userProfile.gender}</Text>
          <Text><strong>Weight:</strong> {userProfile.weight} kg</Text>
          <Text><strong>Height:</strong> {userProfile.height} cm</Text>
          <Text><strong>Goal:</strong> {userProfile.goal || 'Not specified'}</Text>
          <Text><strong>Fitness Level:</strong> {userProfile.fitnessLevel || 'Not specified'}</Text>
          <Text fontSize="sm" color="gray.500" mt={2}>This information is read-only and used for workout plan generation.</Text>
        </Box>
      )}
      <Box w="full" p={5} shadow="md" borderWidth="1px">
        <VStack as="form" onSubmit={handleCreatePlan}>
          <FormControl isRequired>
            <FormLabel htmlFor="name">Workout Plan Name</FormLabel>
            <Input
              id="name"
              placeholder="Enter workout plan name"
              value={newPlan.name}
              onChange={(e) => setNewPlan({ ...newPlan, name: e.target.value })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="duration">Duration (in minutes)</FormLabel>
            <Input
              id="duration"
              placeholder="Enter duration"
              type="number"
              value={newPlan.duration}
              onChange={(e) => setNewPlan({ ...newPlan, duration: Number(e.target.value) })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="intensity">Intensity</FormLabel>
            <Select
              id="intensity"
              placeholder="Select intensity"
              value={newPlan.intensity}
              onChange={(e) => setNewPlan({ ...newPlan, intensity: e.target.value })}
            >
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="availableEquipment">Available Equipment</FormLabel>
            <CheckboxGroup
              value={newPlan.availableEquipment}
              onChange={(value) => setNewPlan({ ...newPlan, availableEquipment: value })}
            >
              <VStack align="start">
                <Checkbox value="Dumbbells">Dumbbells</Checkbox>
                <Checkbox value="Barbell">Barbell</Checkbox>
                <Checkbox value="Resistance Bands">Resistance Bands</Checkbox>
                <Checkbox value="Bodyweight">Bodyweight</Checkbox>
              </VStack>
            </CheckboxGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="workoutDuration">Workout Duration (minutes)</FormLabel>
            <Input
              id="workoutDuration"
              placeholder="Enter workout duration"
              type="number"
              value={newPlan.workoutDuration}
              onChange={(e) => setNewPlan({ ...newPlan, workoutDuration: Number(e.target.value) })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="workoutFrequency">Workout Frequency (per week)</FormLabel>
            <Input
              id="workoutFrequency"
              placeholder="Enter workout frequency"
              type="number"
              value={newPlan.workoutFrequency}
              onChange={(e) => setNewPlan({ ...newPlan, workoutFrequency: Number(e.target.value) })}
            />
          </FormControl>
          <Button type="submit" colorScheme="blue">Create Workout Plan</Button>
        </VStack>
      </Box>
      <EnhancedWorkoutPlanner
        workoutPlans={workoutPlans}
        userProfile={userProfile}
        onEditPlan={handleEditPlanOpen}
        onDeletePlan={handleDeletePlan}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Workout Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel htmlFor="name">Workout Plan Name</FormLabel>
              <Input
                id="name"
                placeholder="Enter workout plan name"
                value={editingPlan?.name}
                onChange={(e) => setEditingPlan({ ...editingPlan, name: e.target.value })}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="duration">Duration (in minutes)</FormLabel>
              <Input
                id="duration"
                placeholder="Enter duration"
                type="number"
                value={editingPlan?.duration}
                onChange={(e) => setEditingPlan({ ...editingPlan, duration: Number(e.target.value) })}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpdatePlan}>
              Save Changes
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default WorkoutPlanner;
