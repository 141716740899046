const deepEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const extractRolesFromIdToken = (idToken) => {
  try {
    const payloadBase64 = idToken.split('.')[1];
    if (DEBUG) console.log('Base64 Payload:', payloadBase64); // Log the base64 payload
    const decodedPayload = JSON.parse(atob(payloadBase64));
    if (DEBUG) console.log('Decoded Payload:', decodedPayload); // Log the decoded payload
    const roles = decodedPayload.roles || [];
    if (DEBUG) console.log('Extracted Roles:', roles); // Log the extracted roles
    return roles;
  } catch (error) {
    if (DEBUG) console.error('Error extracting roles from ID token:', error);
    return [];
  }
};

const DEBUG = true; // Set to true to enable debug logging

export { deepEqual, extractRolesFromIdToken, DEBUG };
