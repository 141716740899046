import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthService from '../services/authService.js';

const AuthResponse = () => {
  const navigate = useNavigate();
  const { getToken } = useAuthService();

  useEffect(() => {
    const handleResponse = async () => {
      try {
        const accessToken = await getToken(); // Get the access token from authService
        localStorage.setItem('jwtToken', accessToken); // Store the access token in local storage

        navigate('/'); // Redirect to your desired route after successful login
      } catch (error) {
        console.error('Error handling authentication:', error);
        // Redirect to the homepage in case of an error
        navigate('/');
      }
    };
    handleResponse();
  }, [navigate, getToken]);

  return <div>Processing authentication response...</div>;
};

export default AuthResponse;
