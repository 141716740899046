import React, { useState, useContext, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Text,
  VStack,
  List,
  ListItem,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext.js';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const validateUsername = (username) => {
  return username.length > 0;
};

const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return {
    isValid: password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar,
    errors: [
      password.length < minLength && 'Password must be at least 8 characters long',
      !hasUpperCase && 'Password must contain at least one uppercase letter',
      !hasLowerCase && 'Password must contain at least one lowercase letter',
      !hasNumber && 'Password must contain at least one number',
      !hasSpecialChar && 'Password must contain at least one special character',
    ].filter(Boolean),
  };
};

const Login = () => {
  const [input, setInput] = useState({
    username: '',
    password: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({
    username: '',
    password: [],
    form: '',
  });
  const navigate = useNavigate();
  const { login, updateAuthState, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    setError((prevError) => ({
      ...prevError,
      [name]: '',
    }));

    if (name === 'password') {
      const { isValid, errors } = validatePassword(value);
      setError((prevError) => ({
        ...prevError,
        password: isValid ? [] : errors,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    console.log('Form submission initiated.');
    console.log('Login attempt with:', { username: input.username, password: '********' });

    if (!validateUsername(input.username)) {
      setError((prevError) => ({
        ...prevError,
        username: 'Invalid username',
      }));
      isValid = false;
    }

    const { isValid: isPasswordValid, errors: passwordErrors } = validatePassword(input.password);
    if (!isPasswordValid) {
      setError((prevError) => ({
        ...prevError,
        password: passwordErrors,
      }));
      isValid = false;
    } else {
      setError((prevError) => ({
        ...prevError,
        password: [],
      }));
    }

    if (isValid) {
      setIsSubmitting(true);
      try {
        console.log('Attempting to log in.');
        const loginResponse = await login(input);
        console.log('Login response:', loginResponse);

        if (loginResponse && loginResponse.isAuthenticated) {
          console.log('Login successful');
          updateAuthState({
            isAuthenticated: true,
            username: loginResponse.username,
            roles: loginResponse.roles,
            userId: loginResponse.userId
          });

          if (loginResponse.passwordNeedsUpdate) {
            console.log('Password needs update');
            navigate('/update-password');
          } else {
            navigate('/');
          }
        } else {
          console.error('Login failed: Invalid response');
          setError((prevError) => ({
            ...prevError,
            form: 'Login failed: Invalid response from server',
          }));
        }
      } catch (error) {
        console.error('Login error:', error);
        console.error('Login error details:', {
          message: error.message,
          response: error.response ? {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers,
          } : 'No response',
          request: error.request ? 'Request was made but no response was received' : 'No request was made',
        });
        setError((prevError) => ({
          ...prevError,
          form: `Login failed: ${error.response?.data?.message || error.message || 'Unknown error'}`,
        }));
      }
      setIsSubmitting(false);
    }
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <Box p={8}>
      <form onSubmit={handleSubmit}>
        <FormControl isInvalid={!!error.username}>
          <FormLabel htmlFor="username">Username</FormLabel>
          <Input
            id="username"
            type="text"
            name="username"
            value={input.username}
            onChange={handleInputChange}
          />
          <FormErrorMessage>{error.username}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={error.password.length > 0} mt={4}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            id="password"
            type="password"
            name="password"
            value={input.password}
            onChange={handleInputChange}
          />
          {error.password.length > 0 && (
            <List spacing={1} mt={2}>
              {error.password.map((err, index) => (
                <ListItem key={index}>
                  <Text color="red.500" fontSize="sm">
                    • {err}
                  </Text>
                </ListItem>
              ))}
            </List>
          )}
        </FormControl>
        {error.form && <FormErrorMessage>{error.form}</FormErrorMessage>}
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
        >
          Login
        </Button>
      </form>
    </Box>
  );
};

export default Login;
