import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const ManageRoles = () => {
  return (
    <Box p={4}>
      <Heading as="h1" mb={4}>Manage Roles</Heading>
      <Text mb={4}>This is the Manage Roles page. Here you can view and manage all roles.</Text>
    </Box>
  );
};

export default ManageRoles;
