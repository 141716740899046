import React, { useState } from 'react';
import { FormErrorMessage, Button, Box, Input, FormControl, FormLabel, Text, VStack, List, ListItem, useToast } from '@chakra-ui/react';
import axios from 'axios';

// Password validation function
const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return {
    isValid: password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar,
    errors: [
      password.length < minLength && 'Password must be at least 8 characters long',
      !hasUpperCase && 'Password must contain at least one uppercase letter',
      !hasLowerCase && 'Password must contain at least one lowercase letter',
      !hasNumber && 'Password must contain at least one number',
      !hasSpecialChar && 'Password must contain at least one special character',
    ].filter(Boolean),
  };
};

// Username validation function to restrict spaces
const validateUsername = (username) => {
  const trimmedUsername = username.trim(); // Remove leading/trailing whitespace
  const hasNoSpaces = !/\s/.test(trimmedUsername); // Ensure no spaces within
  const minLength = 3;
  const maxLength = 20; // Example max length
  const allowedCharacters = /^[a-zA-Z0-9_-]+$/; // Alphanumeric, underscores, dashes
  
  const isValid = hasNoSpaces && 
                  trimmedUsername.length >= minLength && 
                  trimmedUsername.length <= maxLength && 
                  allowedCharacters.test(trimmedUsername);
  
  return {
    isValid,
    errors: [
      !hasNoSpaces && 'Username cannot contain spaces',
      trimmedUsername.length < minLength && `Username must be at least ${minLength} characters long`,
      trimmedUsername.length > maxLength && `Username cannot exceed ${maxLength} characters`,
      !allowedCharacters.test(trimmedUsername) && 'Username can only contain letters, numbers, underscores, or dashes',
    ].filter(Boolean),
  };
};


const Register = () => {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({
    form: '',
    password: [],
    username: [],
  });
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'password') {
      const { isValid, errors } = validatePassword(value);
      setError((prevError) => ({
        ...prevError,
        password: isValid ? [] : errors,
      }));
    }

    if (name === 'username') {
      const { isValid, errors } = validateUsername(value);
      setError((prevError) => ({
        ...prevError,
        username: isValid ? [] : errors,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Revalidate username and password before submission
    const { isValid: isUsernameValid, errors: usernameErrors } = validateUsername(formData.username);
    const { isValid: isPasswordValid, errors: passwordErrors } = validatePassword(formData.password);

    if (!isUsernameValid || !isPasswordValid) {
      setError({
        username: usernameErrors,
        password: passwordErrors,
        form: '',
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/register`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
      });
      toast({
        title: "Account created.",
        description: "Your account has been successfully created. You can now log in.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Redirect to login page after a short delay
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    } catch (e) {
      setError((prevError) => ({
        ...prevError,
        form: 'Failed to register: ' + e.message,
      }));
      toast({
        title: "Registration failed.",
        description: e.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Box p={8}>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired isInvalid={error.username.length > 0}>
          <FormLabel>Username</FormLabel>
          <Input
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
          {error.username.length > 0 && (
            <List spacing={1} mt={2}>
              {error.username.map((err, index) => (
                <ListItem key={index}>
                  <Text color="red.500" fontSize="sm">
                    • {err}
                  </Text>
                </ListItem>
              ))}
            </List>
          )}
        </FormControl>

        <FormControl isRequired mt={4}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl isRequired mt={4} isInvalid={error.password.length > 0}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {error.password.length > 0 && (
            <List spacing={1} mt={2}>
              {error.password.map((err, index) => (
                <ListItem key={index}>
                  <Text color="red.500" fontSize="sm">
                    • {err}
                  </Text>
                </ListItem>
              ))}
            </List>
          )}
        </FormControl>

        {error.form && <FormErrorMessage>{error.form}</FormErrorMessage>}

        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
        >
          Register
        </Button>
      </form>
    </Box>
  );
};

export default Register;
