import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Text,
  VStack,
  List,
  ListItem,
  Progress,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext.js';
import axios from 'axios';

const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return {
    isValid: password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar,
    errors: [
      password.length < minLength && 'Password must be at least 8 characters long',
      !hasUpperCase && 'Password must contain at least one uppercase letter',
      !hasLowerCase && 'Password must contain at least one lowercase letter',
      !hasNumber && 'Password must contain at least one number',
      !hasSpecialChar && 'Password must contain at least one special character',
    ].filter(Boolean),
    strength: (password.length >= minLength ? 1 : 0) +
              (hasUpperCase ? 1 : 0) +
              (hasLowerCase ? 1 : 0) +
              (hasNumber ? 1 : 0) +
              (hasSpecialChar ? 1 : 0),
  };
};

const UpdatePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({
    password: [],
    confirmPassword: '',
    form: '',
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [gracePeriodDays, setGracePeriodDays] = useState(null);
  const navigate = useNavigate();
  const { updateAuthState } = useAuth();
  const toast = useToast();

  useEffect(() => {
    const storedGracePeriodDays = localStorage.getItem('gracePeriodDays');
    if (storedGracePeriodDays) {
      setGracePeriodDays(parseInt(storedGracePeriodDays, 10));
    }
  }, []);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const { isValid, errors, strength } = validatePassword(newPassword);
    setError((prevError) => ({
      ...prevError,
      password: isValid ? [] : errors,
    }));
    setPasswordStrength(strength);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setError((prevError) => ({
      ...prevError,
      confirmPassword: newConfirmPassword === password ? '' : 'Passwords do not match',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (password !== confirmPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: 'Passwords do not match',
      }));
      setIsSubmitting(false);
      return;
    }

    const { isValid, errors } = validatePassword(password);
    if (!isValid) {
      setError((prevError) => ({
        ...prevError,
        password: errors,
      }));
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/update-password`, {
        newPassword: password,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });

      if (response.data.success) {
        toast({
          title: 'Password updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        updateAuthState({ passwordNeedsUpdate: false });
        navigate('/');
      } else {
        setError((prevError) => ({
          ...prevError,
          form: 'Failed to update password. Please try again.',
        }));
      }
    } catch (error) {
      setError((prevError) => ({
        ...prevError,
        form: `Error updating password: ${error.response?.data?.message || error.message}`,
      }));
    }

    setIsSubmitting(false);
  };

  const handleSkip = () => {
    navigate('/');
  };

  return (
    <Box p={8}>
      <VStack spacing={4} align="stretch">
        <Text fontSize="xl" fontWeight="bold">Update Your Password</Text>
        {gracePeriodDays !== null && (
          <Text>You have {gracePeriodDays} days left to update your password.</Text>
        )}
        <form onSubmit={handleSubmit}>
          <FormControl isInvalid={error.password.length > 0}>
            <FormLabel htmlFor="password">New Password</FormLabel>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <Progress value={passwordStrength * 20} max={100} mt={2} />
            <Text mt={1} fontSize="sm">Password strength: {['Very Weak', 'Weak', 'Fair', 'Good', 'Strong'][passwordStrength]}</Text>
            {error.password.length > 0 && (
              <List spacing={1} mt={2}>
                {error.password.map((err, index) => (
                  <ListItem key={index}>
                    <Text color="red.500" fontSize="sm">
                      • {err}
                    </Text>
                  </ListItem>
                ))}
              </List>
            )}
          </FormControl>
          <FormControl isInvalid={!!error.confirmPassword} mt={4}>
            <FormLabel htmlFor="confirmPassword">Confirm New Password</FormLabel>
            <Input
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {error.confirmPassword && (
              <FormErrorMessage>{error.confirmPassword}</FormErrorMessage>
            )}
          </FormControl>
          {error.form && <Text color="red.500" mt={2}>{error.form}</Text>}
          <Button
            mt={4}
            colorScheme="teal"
            isLoading={isSubmitting}
            type="submit"
            width="full"
          >
            Update Password
          </Button>
        </form>
        {gracePeriodDays !== null && (
          <Button onClick={handleSkip} variant="outline" width="full">
            Skip for now
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default UpdatePassword;
