import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, List, ListItem, VStack, Divider } from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext.js';
import UserProfile from './UserProfile.js';

const MyAccount = () => {
  const { userId, username, roles } = useAuth();
  const [userDetails, setUserDetails] = useState({
    roles: [],
    username: '',
    userId: ''
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (userId) {
          setUserDetails({
            roles,
            username,
            userId
          });
        } else {
          console.warn('No user ID found.');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [userId, username, roles]);

  return (
    <Box p={4}>
      <VStack spacing={8} align="stretch">
        <Box>
          <Heading as="h1" mb={4}>My Account</Heading>
          <Text fontSize="lg" mb={2}>Username: {userDetails.username}</Text>
          <Text fontSize="lg" mb={2}>User ID: {userDetails.userId}</Text>
          <Heading as="h2" size="md" mb={2}>Roles and Authorities</Heading>
          <List spacing={2}>
            {userDetails.roles.map((role, index) => (
              <ListItem key={index}>{role}</ListItem>
            ))}
          </List>
        </Box>
        <Divider />
        <UserProfile />
      </VStack>
    </Box>
  );
};

export default MyAccount;
