import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { extractRolesFromIdToken } from '../utils.js';

const DEBUG = process.env.NODE_ENV === 'development';
const API_URL = process.env.REACT_APP_BACKEND_URL;
const TOKEN_KEY = 'jwtToken';
const USER_ID_KEY = 'userId';

class AuthError extends Error {
  constructor(message, type) {
    super(message);
    this.name = 'AuthError';
    this.type = type;
  }
}

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      authService.logout();
      throw new AuthError('Your session has expired. Please log in again.', 'SESSION_EXPIRED');
    }
    return Promise.reject(error);
  }
);

const authService = {
  login: async (credentials) => {
    try {
      if (DEBUG) console.log('Login attempt with credentials:', { ...credentials, password: '[REDACTED]' });

      const response = await api.post('/api/users/login', credentials);
      if (DEBUG) console.log('Login response:', { status: response.status, data: { ...response.data, token: '[REDACTED]' } });

      const { token, username, userId } = response.data;
      if (!token || typeof token !== 'string') {
        throw new AuthError('Invalid response from server. Please try again.', 'INVALID_RESPONSE');
      }
      if (!username || typeof username !== 'string') {
        throw new AuthError('Invalid username received. Please try again.', 'INVALID_USERNAME');
      }

      localStorage.setItem('jwtToken', token);
      localStorage.setItem(USER_ID_KEY, userId);
      if (DEBUG) console.log('Token and userId stored in localStorage');

      let decodedToken = jwtDecode(token);
      let roles = extractRolesFromIdToken(token);
      if (DEBUG) console.log('Extracted roles:', roles);

      return { token, username, roles, userId };
    } catch (error) {
      console.error('Login error:', error.message);
      if (error instanceof AuthError) {
        throw error;
      } else if (error.response) {
        console.error('Server response:', {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers,
        });
        switch (error.response.status) {
          case 400:
            throw new AuthError('Invalid login credentials. Please check your username and password.', 'INVALID_CREDENTIALS');
          case 403:
            throw new AuthError('Your account has been locked. Please contact support.', 'ACCOUNT_LOCKED');
          case 404:
            throw new AuthError('User not found. Please check your username.', 'USER_NOT_FOUND');
          case 500:
            throw new AuthError('Server error. Please try again later.', 'SERVER_ERROR');
          default:
            throw new AuthError('An unexpected error occurred. Please try again.', 'UNKNOWN_ERROR');
        }
      } else if (error.request) {
        console.error('No response received. Request details:', error.request);
        throw new AuthError('Unable to reach the server. Please check your internet connection and try again.', 'NETWORK_ERROR');
      } else {
        console.error('Error setting up request:', error.message);
        throw new AuthError('An unexpected error occurred. Please try again.', 'UNKNOWN_ERROR');
      }
    }
  },

  logout: () => {
    try {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_ID_KEY);
      if (DEBUG) console.log('Logged out, token and userId removed from localStorage');
    } catch (error) {
      console.error('Error removing token and userId from localStorage:', error);
      throw new AuthError('Failed to complete logout. Please clear your browser cache and try again.', 'LOGOUT_ERROR');
    }
  },

  getToken: () => {
    try {
      const token = localStorage.getItem(TOKEN_KEY);
      if (DEBUG) console.log('Token retrieved:', token ? `${token.substring(0, 10)}...` : 'null');
      return token;
    } catch (error) {
      console.error('Error retrieving token from localStorage:', error);
      throw new AuthError('Failed to retrieve authentication token. Please log in again.', 'TOKEN_RETRIEVAL_ERROR');
    }
  },

  getUserId: () => {
    try {
      const userId = localStorage.getItem(USER_ID_KEY);
      if (DEBUG) console.log('UserId retrieved:', userId);
      return userId;
    } catch (error) {
      console.error('Error retrieving userId from localStorage:', error);
      throw new AuthError('Failed to retrieve user ID. Please log in again.', 'USER_ID_RETRIEVAL_ERROR');
    }
  },

  isAuthenticated: () => {
    try {
      const token = authService.getToken();
      if (!token) return false;

      const decodedToken = jwtDecode(token);
      return decodedToken.exp * 1000 > Date.now();
    } catch (error) {
      if (DEBUG) console.error('Error checking authentication status:', error);
      return false;
    }
  },

  getUserInfo: () => {
    try {
      const token = authService.getToken();
      if (!token) return null;

      const decodedToken = jwtDecode(token);
      return {
        username: decodedToken.sub,
        roles: extractRolesFromIdToken(token),
        userId: authService.getUserId(),
      };
    } catch (error) {
      if (DEBUG) console.error('Error getting user info:', error);
      throw new AuthError('Failed to retrieve user information. Please log in again.', 'USER_INFO_ERROR');
    }
  },
};

export default authService;