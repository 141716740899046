export const FitnessGoal = {
  WEIGHT_LOSS: 'WEIGHT_LOSS',
  MUSCLE_GAIN: 'MUSCLE_GAIN',
  ENDURANCE: 'ENDURANCE',
  FLEXIBILITY: 'FLEXIBILITY',
  GENERAL_FITNESS: 'GENERAL_FITNESS',
  BODY_RECOMPOSITION: 'BODY_RECOMPOSITION'
};

export const FitnessLevel = {
  BEGINNER: 'BEGINNER',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED'
};
