import React from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Image,
  Container,
  Flex,
  SimpleGrid,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaUtensils, FaDumbbell, FaBrain, FaChartLine } from 'react-icons/fa';

const Feature = ({ icon: IconComponent, title, text }) => (
  <Box textAlign="center" px={4} py={8} bg={useColorModeValue('gray.100', 'gray.700')} borderRadius="lg">
    <Box as={IconComponent} w={10} h={10} color="brand.500" mb={4} />
    <Heading as="h3" size="md" mb={2} color={useColorModeValue('gray.800', 'white')}>
      {title}
    </Heading>
    <Text color={useColorModeValue('gray.600', 'gray.400')}>{text}</Text>
  </Box>
);

const HomePage = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Box>
      {/* Hero Section */}
      <Box
        bgImage={`url('https://healthmatewebstorage.blob.core.windows.net/healthmatewebimages/images/diverse_group01.png')`}
        bgPosition="top 30%"
        bgRepeat="no-repeat"
        bgSize="cover"
        position="relative"
        aria-label="Diverse group of people in workout attire, smiling and looking energetic"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.6))"
          zIndex="1"
        />
        <Container maxW="container.xl" h="100%" position="relative" zIndex="2" py={{ base: 20, md: 24 }}>
          <Flex direction="column" justify="center" align={{ base: "center", md: "flex-start" }} h="100%" textAlign={{ base: "center", md: "left" }}>
            <Heading
              as="h1"
              size={{ base: '3xl', md: '4xl', lg: '5xl' }}
              mb={4}
              color="white"
              fontWeight="bold"
              textShadow="2px 2px 4px rgba(0,0,0,0.6)"
            >
              Transform Your Health with Alxelion
            </Heading>
            <Heading
              as="h2"
              size={{ base: 'xl', md: '2xl' }}
              mb={6}
              fontWeight="normal"
              color="white"
              textShadow="2px 2px 3px rgba(0,0,0,0.5)"
            >
              Your AI-Powered Personal Health & Fitness Coach
            </Heading>
            <Text
              fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}
              mb={8}
              maxW="700px"
              color="white"
              textShadow="1px 1px 2px rgba(0,0,0,0.4)"
            >
              Discover a revolutionary approach to wellness. Alxelion's AI creates personalized nutrition and fitness plans, making your health journey more effective and enjoyable than ever before.
            </Text>
            <Button
              as={RouterLink}
              to="/register"
              bg="accent.500"
              color="white"
              size="lg"
              px={8}
              fontSize={{ base: 'md', md: 'lg' }}
              _hover={{ bg: 'accent.600' }}
              _active={{ bg: 'accent.700' }}
              boxShadow="md"
            >
              Begin Your Transformation Today
            </Button>
          </Flex>
        </Container>
      </Box>

      {/* Key Features Section */}
      <Box py={{ base: 12, md: 16 }} bg={bgColor}>
        <Container maxW="container.xl">
          <VStack spacing={{ base: 6, md: 8 }}>
            <Heading as="h2" size="xl" textAlign="center" color={textColor}>
              Key Features
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={{ base: 8, lg: 10 }} width="full">
              <Feature
                icon={FaUtensils}
                title="Smart Meal Planner"
                text="Personalized meal plans based on your preferences, dietary needs, and goals."
              />
              <Feature
                icon={FaDumbbell}
                title="Custom Workout Creator"
                text="Tailored workout routines that evolve with your progress."
              />
              <Feature
                icon={FaBrain}
                title="AI-Powered Insights"
                text="Intelligent recommendations that adapt to your lifestyle and performance."
              />
              <Feature
                icon={FaChartLine}
                title="Progress Tracking"
                text="Visualize your journey with easy-to-understand charts and metrics."
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      {/* How It Works Section */}
      <Box py={16} bg={bgColor}>
        {/* How It Works content will go here */}
        <Container maxW="container.xl">
          <Heading as="h2" size="xl" textAlign="center" color={textColor} mb={8}>
            How It Works
          </Heading>
          {/* Add your How It Works content here */}
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box py={16}>
        {/* Testimonials content will go here */}
        <Container maxW="container.xl">
          <Heading as="h2" size="xl" textAlign="center" color={textColor} mb={8}>
            What Our Users Say
          </Heading>
          {/* Add your Testimonials content here */}
        </Container>
      </Box>

{/* Final Call-to-Action Section */}
<Box py={16} bg="teal.500" color="white">
  <Container maxW="container.xl">
    <Flex direction="column" justify="center" align="center" textAlign="center">
      <Heading as="h2" size="4xl" mb={4} fontWeight="bold">
        Unlock Your Full Potential with Alxelion
      </Heading>
      <Text fontSize="2xl" mb={8}>
        Revolutionize your health and fitness journey with personalized AI-powered plans. Join the Alxelion community today and transform your life.
      </Text>
      <Button
        as={RouterLink}
        to="/register"
        bg="white"
        color="teal.500"
        size="lg"
        px={10}
        py={6}
        fontSize="xl"
        fontWeight="bold"
        _hover={{ bg: 'gray.200' }}
        _active={{ bg: 'gray.300' }}
        boxShadow="xl"
      >
        Start Your Transformation
      </Button>
    </Flex>
  </Container>
</Box>
    </Box>
  );
};

export default HomePage;