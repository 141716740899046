import React, { createContext, useState, useEffect, useMemo, useCallback, useContext, useRef, useLayoutEffect } from 'react';
import { extractRolesFromIdToken } from '../utils.js';
import { jwtDecode } from 'jwt-decode';
import authService from '../services/authService.js';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(() => {
    const token = localStorage.getItem(process.env.REACT_APP_JWTTOKEN_KEY);
    const userId = localStorage.getItem('userId');
    if (token && userId) {
      try {
        const decodedToken = jwtDecode(token);
        const username = decodedToken.sub;
        const roles = extractRolesFromIdToken(token);
        return {
          isAuthenticated: true,
          username,
          roles,
          userId,
        };
      } catch (error) {
        console.error('Error decoding token during initialization:', error);
        return {
          isAuthenticated: false,
          username: '',
          roles: [],
          userId: '',
        };
      }
    } else {
      return {
        isAuthenticated: false,
        username: '',
        roles: [],
        userId: '',
      };
    }
  });

  const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_JWTTOKEN_KEY);
  };

  const updateAuthState = useCallback((newState) => {
    setAuthState((prevState) => {
      const updatedState = { ...prevState, ...newState };
      if (newState.userId !== undefined) {
        updatedState.userId = newState.userId;
      }
      return JSON.stringify(updatedState) !== JSON.stringify(prevState) ? updatedState : prevState;
    });
  }, []);

  const logout = useCallback(() => {
    console.log('Logging out, removing token and userId from localStorage');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userId');
    updateAuthState({
      isAuthenticated: false,
      username: '',
      roles: [],
      userId: ''
    });
  }, [updateAuthState]);

  const login = async (credentials) => {
    try {
      console.log('Attempting login with credentials:', { ...credentials, password: '[REDACTED]' });
      const loginResult = await authService.login(credentials);
      // Store the JWT token in localStorage
      localStorage.setItem(process.env.REACT_APP_JWTTOKEN_KEY, loginResult.token);
      localStorage.setItem('userId', loginResult.userId);
      console.log('JWT token and userId stored in localStorage');

      updateAuthState({
        isAuthenticated: true,
        username: loginResult.username,
        roles: loginResult.roles,
        userId: loginResult.userId,
      });

      console.log('Auth state updated after successful login');
      console.log('Login process completed successfully');

      // Refresh the page to apply changes
      window.location.reload();

      return loginResult;

    } catch (error) {
      console.error('Login error:', error.message);
      console.error('Login error details:', {
        response: error.response ? {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers,
        } : 'No response',
        request: error.request ? 'Request was made but no response was received' : 'No request was made',
      });
      localStorage.removeItem(process.env.REACT_APP_JWTTOKEN_KEY);
      localStorage.removeItem('userId');
      console.log('JWT token and userId removed from localStorage due to login error');
      updateAuthState({
        isAuthenticated: false,
        username: '',
        roles: [],
        userId: '',
      });
      throw error; // Re-throw the error to be handled by the component
    }
  };

  const checkTokenExpiration = useCallback(() => {
    console.log('Checking token expiration...');
    const token = localStorage.getItem(process.env.REACT_APP_JWTTOKEN_KEY);
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        if (decodedToken.exp < currentTime) {
          console.log('Token expired, logging out');
          logout();
        } else {
          const timeUntilExpiration = decodedToken.exp - currentTime;
          console.log(`Token is valid. Expires in ${timeUntilExpiration.toFixed(2)} seconds`);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        console.log('Invalid token, logging out');
        logout();
      }
    } else {
      console.log('No token found in localStorage');
    }
  }, [logout]);

  const initializeAuth = useCallback(() => {
    console.log('Initializing auth...');
    const token = localStorage.getItem(process.env.REACT_APP_JWTTOKEN_KEY);
    const userId = localStorage.getItem('userId');
    if (token && userId) {
      try {
        console.log('Token and userId found in localStorage');
        const decodedToken = jwtDecode(token);
        const username = decodedToken.sub; // Assuming 'sub' claim contains the username
        const roles = extractRolesFromIdToken(token);

        console.log('Auth initialized with username:', username, 'roles:', roles, 'and userId:', userId);
        setAuthState((prevState) => {
          if (
            prevState.isAuthenticated !== true ||
            prevState.username !== username ||
            JSON.stringify(prevState.roles) !== JSON.stringify(roles) ||
            prevState.userId !== userId
          ) {
            console.log('Updating auth state');
            return {
              isAuthenticated: true,
              username,
              roles,
              userId,
            };
          }
          return prevState;
        });
      } catch (error) {
        console.error('Error initializing auth:', error);
        console.log('Logging out due to initialization error');
        logout();
      }
    } else {
      console.log('Token or userId not found in localStorage, initializing as logged out');
      logout();
    }
  }, [logout]);

  useLayoutEffect(() => {
    initializeAuth(); // Initialize authentication state on component mount
    const intervalId = setInterval(checkTokenExpiration, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [initializeAuth, checkTokenExpiration]);

  const contextValue = useMemo(() => ({
    ...authState,
    login,
    logout,
    updateAuthState,
    getToken,
  }), [authState]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
