// src/hooks/useRoleBasedAccess.js
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext.js';

const useRoleBasedAccess = (requiredRoles) => {
  const { roles } = useContext(AuthContext);

  if (!requiredRoles || requiredRoles.length === 0) {
    return true;
  }

  return requiredRoles.some(role => roles.includes(role));
};

export default useRoleBasedAccess;
