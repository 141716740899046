import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Button, Spinner, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import authService from '../services/authService.js';

const AdminPage = () => {
  const navigate = useNavigate();
  const [dashboardMetrics, setDashboardMetrics] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('REACT_APP_BACKEND_URL:', process.env.REACT_APP_BACKEND_URL);

    const fetchDashboardMetrics = async () => {
      console.log('Backend URL for dashboard metrics:', process.env.REACT_APP_BACKEND_URL);
      try {
        const token = await authService.getToken();
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/dashboard`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        console.log("scanAvailable value:", data.scanAvailable);
        console.log("scanAvailable type:", typeof data.scanAvailable);

        // Handle undefined or null scanAvailable
        if (data.scanAvailable === undefined || data.scanAvailable === null) {
          data.scanAvailable = 'N/A';
        } else if (typeof data.scanAvailable === 'object') {
          data.scanAvailable = JSON.stringify(data.scanAvailable);
        } else {
          data.scanAvailable = String(data.scanAvailable);
        }

        setDashboardMetrics(data);
        console.log("Rendered scanAvailable:", data.scanAvailable);
      } catch (error) {
        console.error('Error fetching dashboard metrics:', error);
        setError('Failed to fetch dashboard metrics. Please try again later.');
      }
    };

    const fetchUsers = async () => {
      console.log('Backend URL for fetching users:', process.env.REACT_APP_BACKEND_URL);
      try {
        const token = await authService.getToken();
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Failed to fetch users. Please try again later.');
      }
    };

    const fetchData = async () => {
      await fetchDashboardMetrics();
      await fetchUsers();
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleManageUsers = () => {
    navigate('/manage-users');
  };

  const handleManageRoles = () => {
    navigate('/manage-roles');
  };

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <Box p={4}>
      <Heading as="h1" mb={4}>Admin Page</Heading>
      <Text mb={4}>Welcome to the admin page. Here you can manage users, roles, and other administrative tasks.</Text>
      {error && <Text color="red.500" mb={4}>{error}</Text>}
      {dashboardMetrics && (
        <Box mb={4}>
          <Heading as="h2" size="md" mb={2}>Dashboard Metrics</Heading>
          <Text>Registered Users: {dashboardMetrics.registeredUsers}</Text>
          <Text>Active Workout Plans: {dashboardMetrics.activeWorkoutPlans}</Text>
          <Text>Active Subscriptions: {dashboardMetrics.activeSubscriptions}</Text>
          <Text>Nutritional Info Count: {dashboardMetrics.nutritionalInfoCount}</Text>
          <Text>Scan Available: {String(dashboardMetrics.scanAvailable)}</Text>
        </Box>
      )}
      <Button colorScheme="teal" size="md" onClick={handleManageUsers}>Manage Users</Button>
      <Button colorScheme="teal" size="md" ml={4} onClick={handleManageRoles}>Manage Roles</Button>
      <Box mt={8}>
        <Heading as="h2" size="md" mb={2}>Users</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Username</Th>
              <Th>Email</Th>
              <Th>Roles</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.id}>
                <Td>{user.id}</Td>
                <Td>{user.username}</Td>
                <Td>{user.email}</Td>
                <Td>{user.roles.join(', ')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default AdminPage;
