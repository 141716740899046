import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Button, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import axios from 'axios';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    console.log('Backend URL for fetching users:', process.env.REACT_APP_BACKEND_URL);
    try {
      const token = localStorage.getItem('jwtToken'); // Retrieve the token from local storage
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/users`, {
        headers: {
          Authorization: `Bearer ${token}` // Include the token in the request headers
        }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const token = localStorage.getItem('jwtToken'); // Retrieve the token from local storage
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/users/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}` // Include the token in the request headers
        }
      });
      fetchUsers(); // Refresh the user list after deletion
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <Box p={4}>
      <Heading as="h1" mb={4}>Manage Users</Heading>
      <Text mb={4}>This is the Manage Users page. Here you can view and manage all users.</Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Email</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td>{user.username}</Td>
              <Td>{user.email}</Td>
              <Td>
                <Button colorScheme="red" size="sm" onClick={() => handleDeleteUser(user.id)}>Delete</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ManageUsers;
