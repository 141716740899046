// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      50: '#e6fffa',
      100: '#b2f5ea',
      200: '#81e6d9',
      300: '#4fd1c5',
      400: '#38b2ac',
      500: '#319795',
      600: '#2c7a7b',
      700: '#285e61',
      800: '#234e52',
      900: '#1d4044',
    },
    accent: {
      50: '#fff5f5',
      100: '#fed7d7',
      200: '#feb2b2',
      300: '#fc8181',
      400: '#f56565',
      500: '#e53e3e',
      600: '#c53030',
      700: '#9b2c2c',
      800: '#822727',
      900: '#63171b',
    },
  },
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Open Sans", sans-serif',
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50', // Lighter background for better contrast
        color: 'gray.800', // Darker text color for better readability
        fontSize: { base: '16px', md: '18px' }, // Increased font size for better readability
        lineHeight: { base: '1.6', md: '1.8' }, // Increased line height for better readability
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'full',
      },
      variants: {
        solid: {
          bg: 'accent.500',
          color: 'white',
          _hover: {
            bg: 'accent.600',
          },
        },
        outline: {
          borderColor: 'brand.500',
          color: 'brand.500',
          _hover: {
            bg: 'brand.50',
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: 'gray.800',
      },
    },
    Heading: {
      baseStyle: {
        color: 'gray.900', // Darker color for headings
      },
    },
    Feature: {
      baseStyle: {
        bg: 'white',
        p: 6,
        borderRadius: 'md',
        boxShadow: 'md',
      },
    },
    KeyFeaturesSection: {
      baseStyle: {
        bg: 'gray.100',
        py: { base: 12, md: 16 },
      },
    },
    KeyFeaturesSectionHeading: {
      baseStyle: {
        color: 'gray.900', // Darker color for better visibility
        textAlign: 'center',
        mb: { base: 6, md: 8 },
      },
    },
    Navbar: {
      baseStyle: {
        bg: 'white',
        color: 'gray.800',
        boxShadow: 'sm',
        py: 2,
      },
    },
    Hero: { // New component style for the hero section
      baseStyle: {
        bg: 'rgba(0, 0, 0, 0.6)', // Semi-transparent dark overlay
        color: 'white', // White text for maximum contrast
        textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', // Text shadow for better readability
      },
    },
  },
});

export default theme;
