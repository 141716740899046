export const getExerciseImage = (exerciseName) => {
  // Placeholder implementation
  // In a real-world scenario, this function would map exercise names to image URLs
  const defaultImage = 'https://example.com/default-exercise-image.jpg';

  // You could implement a switch statement or object mapping here
  // For now, we'll just return the default image
  return defaultImage;
};

export const calculateStreak = (workoutPlans) => {
  // Placeholder implementation
  // In a real-world scenario, this function would analyze workout plans to calculate the streak

  // For now, we'll just return a random number between 1 and 7
  return Math.floor(Math.random() * 7) + 1;
};
