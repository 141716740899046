
import React from 'react';
import { Box, Flex, Text, Link, Button, useColorMode, useColorModeValue, IconButton, useTheme } from '@chakra-ui/react';

import { Link as RouterLink } from 'react-router-dom';
import { FaHome, FaAppleAlt, FaDumbbell } from 'react-icons/fa';
import { MdLogin, MdPersonAdd, MdPayment } from 'react-icons/md';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { useAuth } from '../context/AuthContext.js';

const NavBar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const theme = useTheme();
  const navBg = useColorModeValue(theme.colors.brand[500], theme.colors.brand[900]);
  const navTextColor = useColorModeValue('white', 'whiteAlpha.900');

  // Move all useColorModeValue calls here
  const accountButtonBg = useColorModeValue('blue.500', 'blue.200');
  const accountButtonColor = useColorModeValue('white', 'gray.800');
  const accountButtonHoverBg = useColorModeValue('blue.600', 'blue.300');
  const accountButtonHoverColor = useColorModeValue('white', 'gray.900');
  const logoutButtonBorderColor = useColorModeValue('red.500', 'red.200');
  const logoutButtonColor = useColorModeValue('red.500', 'red.200');
  const logoutButtonHoverBg = useColorModeValue('red.500', 'red.200');
  const logoutButtonHoverColor = useColorModeValue('white', 'gray.800');
  const loginButtonBorderColor = useColorModeValue('blue.500', 'blue.200');
  const loginButtonColor = useColorModeValue('blue.500', 'blue.200');
  const loginButtonHoverBg = useColorModeValue('blue.500', 'blue.200');
  const loginButtonHoverColor = useColorModeValue('white', 'gray.800');
  const registerButtonBg = useColorModeValue('blue.500', 'blue.200');
  const registerButtonColor = useColorModeValue('white', 'gray.800');
  const registerButtonHoverBg = useColorModeValue('blue.600', 'blue.300');
  const registerButtonHoverColor = useColorModeValue('white', 'gray.900');

  const { isAuthenticated, roles, username, userId, logout } = useAuth();
  React.useEffect(() => {
    console.log('NavBar: Auth state updated -', { isAuthenticated, roles, username, userId });
  }, [isAuthenticated, roles, username, userId]);

  return (
    <Flex
      bg={navBg}
      color={navTextColor}
      p={4}
      justifyContent="space-between"
      alignItems="center"
      wrap="wrap"
      direction={{ base: 'column', md: 'row' }}
    >
      <Box mb={{ base: 4, md: 0 }}>
        <Text fontSize="lg" fontWeight="bold">Alxelion</Text>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Link
          as={RouterLink}
          to="/"
          px={2}
          display="flex"
          alignItems="center"
          mb={{ base: 0, md: 0 }}
          _hover={{ color: 'white', bg: 'brand.600' }}
        >
          <FaHome size="1.2em" />
          <Text ml={1} fontSize="md" fontWeight="semibold">Home</Text>
        </Link>

        <Link
          as={RouterLink}
          to="/nutritional-guidance"
          px={2}
          display="flex"
          alignItems="center"
          mb={{ base: 0, md: 0 }}
          _hover={{ color: 'white', bg: 'brand.600' }}
        >
          <FaAppleAlt size="1.2em" />
          <Text ml={1} fontSize="md" fontWeight="semibold">Nutritional Guidance</Text>
        </Link>

        <Link
          as={RouterLink}
          to="/workout-planner"
          px={2}
          display="flex"
          alignItems="center"
          mb={{ base: 0, md: 0 }}
          _hover={{ color: 'white', bg: 'brand.600' }}
        >
          <FaDumbbell size="1.2em" />
          <Text ml={1} fontSize="md" fontWeight="semibold">Workout Planner</Text>
        </Link>

        <Link
          as={RouterLink}
          to="/pricing"
          px={2}
          display="flex"
          alignItems="center"
          mb={{ base: 0, md: 0 }}
          _hover={{ color: 'white', bg: 'brand.600' }}
        >
          <MdPayment size="1.2em" />
          <Text ml={1} fontSize="md" fontWeight="semibold">Pricing</Text>
        </Link>
        {isAuthenticated && roles.includes('ROLE_ADMIN') && (
          <Link as={RouterLink} to="/admin" px={2} display="flex" alignItems="center" _hover={{ color: 'white', bg: 'brand.600' }}>
            <Text ml={1} fontSize="md" fontWeight="semibold">Admin</Text>
          </Link>
        )}
        <IconButton
          ml={{ base: 0, md: 4 }}
          icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          isRound={true}
          size="md"
          onClick={toggleColorMode}
          variant="ghost"
          colorScheme="blue"
        />

        {isAuthenticated ? (
          <>
            <Button
              as={RouterLink}
              to={`/my-account`}
              variant="solid"
              size="sm"
              ml={{ base: 0, md: 2 }}
              mt={{ base: 2, md: 0 }}
              bg={accountButtonBg}
              color={accountButtonColor}
              _hover={{ bg: accountButtonHoverBg, color: accountButtonHoverColor }}
            >
              My Account
            </Button>
            <Button
              onClick={logout}
              variant="outline"
              size="sm"
              ml={{ base: 0, md: 2 }}
              mt={{ base: 2, md: 0 }}
              borderColor={logoutButtonBorderColor}
              color={logoutButtonColor}
              _hover={{ bg: logoutButtonHoverBg, color: logoutButtonHoverColor }}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              as={RouterLink}
              to="/login"
              variant="outline"
              size="sm"
              ml={{ base: 0, md: 4 }}
              mt={{ base: 2, md: 0 }}
              leftIcon={<MdLogin size="1.2em" />}
              borderColor={loginButtonBorderColor}
              color={loginButtonColor}
              _hover={{ bg: loginButtonHoverBg, color: loginButtonHoverColor }}
            >
              Login
            </Button>
            <Button
              as={RouterLink}
              to="/register"
              variant="solid"
              size="sm"
              ml={{ base: 0, md: 2 }}
              mt={{ base: 2, md: 0 }}
              bg={registerButtonBg}
              color={registerButtonColor}
              leftIcon={<MdPersonAdd size="1.2em" />}
              _hover={{ bg: registerButtonHoverBg, color: registerButtonHoverColor }}
            >
              Register
            </Button>
          </>
        )}
      </Box>
    </Flex>
  );
};

export default NavBar;
