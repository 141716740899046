import React, { useState, useEffect } from 'react';
import { ChakraProvider, useToast, Box } from '@chakra-ui/react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './components/NavBar.js';
import Footer from './components/Footer.js';
import NutritionalGuidance from './pages/NutritionalGuidance.js';
import WorkoutPlanner from './pages/WorkoutPlanner.js';
import Register from './pages/Register.js';
import Login from './pages/Login.js';
import HomePage from './pages/HomePage.js';
import PaymentPage from './pages/PaymentPage.js';
import AuthResponse from './pages/AuthResponse.js';
import PricingPage from './components/PricingPage.js';
import MyAccount from './pages/MyAccount.js';
import AdminPage from './pages/AdminPage.js';
import ManageUsers from './pages/ManageUsers.js';
import ManageRoles from './pages/ManageRoles.js';
import MealSuggestions from './pages/MealSuggestions.js'; // Updated import path
import UpdatePassword from './pages/UpdatePassword.js'; // New import
import ConfirmationPage from './components/ConfirmationPage.js';
import theme from './theme.js';
import useRoleBasedAccess from './hooks/useRoleBasedAccess.js';
import { AuthProvider } from './context/AuthContext.js';
import ErrorBoundary from './ErrorBoundary.js';

const ProtectedRoute = ({ element, requiredRoles }) => {
  const hasAccess = useRoleBasedAccess(requiredRoles);
  const toast = useToast();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (!hasAccess) {
      toast({
        title: "Access Denied",
        description: "You need to subscribe to access this feature.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      // Set a timeout to redirect after 5 seconds
      const timer = setTimeout(() => {
        setShouldRedirect(true);
      }, 5000);

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [hasAccess, toast]);

  if (shouldRedirect) {
    return <Navigate to="/pricing" />;
  }

  return hasAccess ? element : null;
};

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <AuthProvider>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <NavBar />
            <Box flex="1">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/nutritional-guidance" element={<ProtectedRoute element={<NutritionalGuidance />} requiredRoles={['ROLE_PREMIUM']} />} />
                <Route path="/meal-suggestions" element={<ProtectedRoute element={<MealSuggestions />} requiredRoles={['ROLE_PREMIUM']} />} />
                <Route path="/workout-planner" element={<ProtectedRoute element={<WorkoutPlanner />} requiredRoles={['ROLE_PREMIUM']} />} />
                <Route path="/workout-planner/generate-plan" element={<ProtectedRoute element={<WorkoutPlanner />} requiredRoles={['ROLE_PREMIUM']} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/update-password" element={<UpdatePassword />} />
                <Route path="/payment" element={<ProtectedRoute element={<PaymentPage />} requiredRoles={['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PREMIUM']} />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/auth-response" element={<AuthResponse />} />
                <Route path="/my-account" element={<ProtectedRoute element={<MyAccount />} requiredRoles={['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PREMIUM']} />} />
                <Route path="/admin" element={<ProtectedRoute element={<AdminPage />} requiredRoles={['ROLE_ADMIN']} />} />
                <Route path="/manage-users" element={<ProtectedRoute element={<ManageUsers />} requiredRoles={['ROLE_ADMIN']} />} />
                <Route path="/manage-roles" element={<ProtectedRoute element={<ManageRoles />} requiredRoles={['ROLE_ADMIN']} />} />
                <Route path="/confirmation" element={<ConfirmationPage />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </AuthProvider>
      </ErrorBoundary>
    </ChakraProvider>
  );
}

export default App;
