import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL+'/api';

const apiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Basic authService object
const authService = {
  logout: () => {
    // Implement logout logic here
    console.log('User logged out');
  }
};

// Enhanced token management
const getToken = async () => {
  try {
    return await AsyncStorage.getItem('jwtToken');
  } catch (error) {
    console.error('Error retrieving token:', error);
    return null;
  }
};

const setToken = async (token) => {
  try {
    await AsyncStorage.setItem('jwtToken', token);
  } catch (error) {
    console.error('Error setting token:', error);
  }
};

export const removeToken = async () => {
  try {
    await AsyncStorage.removeItem('jwtToken');
  } catch (error) {
    console.error('Error removing token:', error);
  }
};

// Centralized API call design
const makeRequest = async (method, url, data = null, config = {}) => {
  try {
    const token = await getToken();
    if (token) {
      apiService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    const response = await apiService({
      method,
      url,
      data,
      ...config,
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Enhanced error handling
export const handleApiError = (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        console.error('Unauthorized access. Redirecting to login...');
        // Implement logout and redirect logic here
        removeToken();
        authService.logout();
        break;
      case 403:
        console.error('Forbidden access.');
        break;
      case 404:
        console.error('Resource not found.');
        break;
      case 500:
        console.error('Internal server error.');
        break;
      default:
        console.error('An error occurred:', error.response.data);
    }
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
};

// Refactored API functions
export const login = async (username, password) => {
  const response = await makeRequest('post', '/users/login', { username, password });
  if (response.token) {
    await setToken(response.token);
  }
  return response;
};

export const getUser = async (userId) => {
  return await makeRequest('get', `/users/${userId}`);
};

export const updateUser = async (userId, userData) => {
  return await makeRequest('put', `/users/${userId}`, userData);
};

export const deleteUser = async (userId) => {
  return await makeRequest('delete', `/users/${userId}`);
};

export const getMedications = async () => {
  return await makeRequest('get', '/medications');
};

export const addMedication = async (medicationData) => {
  return await makeRequest('post', '/medications', medicationData);
};

export const updateMedication = async (medicationId, medicationData) => {
  return await makeRequest('put', `/medications/${medicationId}`, medicationData);
};

export const deleteMedication = async (medicationId) => {
  return await makeRequest('delete', `/medications/${medicationId}`);
};

// Add more API functions as needed

export const fetchUserWorkoutPlans = async (userId) => {
  return await makeRequest('get', `/workout-plans/user/${userId}`);
};

export default {
  login,
  getUser,
  updateUser,
  deleteUser,
  getMedications,
  addMedication,
  updateMedication,
  deleteMedication,
  fetchUserWorkoutPlans,
  get: (url, config) => makeRequest('get', url, null, config),
  makeRequest, // Add makeRequest function to the exported object
  // Add other API functions here
};
