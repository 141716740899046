import React, { useState, useEffect } from 'react';
import {
  Container,
  Heading,
  VStack,
  Box,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import MealSuggestions from '../pages/MealSuggestions.js';
import api from '../services/api.js';
import authService from '../services/authService.js';

const formatGoal = (goal) => {
  const goalMap = {
    'WEIGHT_LOSS': 'Weight Loss',
    'MUSCLE_GAIN': 'Muscle Gain',
    'ENDURANCE': 'Endurance',
    'GENERAL_FITNESS': 'General Fitness'
  };
  return goalMap[goal] || goal;
};

const NutritionalGuidance = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [mealPlans, setMealPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProfileAndMealPlans = async () => {
      try {
        const userId = localStorage.getItem('userId');
        console.log('Fetching data for userId:', userId);
        const token = authService.getToken();

        if (!token) {
          throw new Error('No authentication token found');
        }

        const headers = {
          'Authorization': `Bearer ${token}`
        };

        const [profileResponse, mealPlansResponse] = await Promise.all([
          api.get(`/api/user-profile/workout-nutrition/${userId}`, { headers }),
          api.get(`/api/nutritionalinfo/mealplans/${userId}`, { headers })
        ]);
        console.log('User profile response:', profileResponse.data);
        console.log('Meal plans response:', JSON.stringify(mealPlansResponse.data, null, 2));
        setUserProfile(profileResponse.data);
        setMealPlans(mealPlansResponse.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load user data');
        setLoading(false);
      }
    };

    fetchUserProfileAndMealPlans();
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Text color="red.500">{error}</Text>;

  console.log('Rendering component with mealPlans:', mealPlans);

  return (
    <Container maxW="container.xl" centerContent>
      <VStack spacing={8} align="stretch" width="100%">
        <Heading as="h1" size="xl" textAlign="center">
          Nutritional Guidance
        </Heading>
        {userProfile && (
          <Box w="full" p={5} shadow="md" borderWidth="1px">
            <Heading size="md" mb={4}>User Profile (Read-only)</Heading>
            <Text><strong>Age:</strong> {userProfile.age}</Text>
            <Text><strong>Gender:</strong> {userProfile.gender}</Text>
            <Text><strong>Weight:</strong> {userProfile.weight} kg</Text>
            <Text><strong>Height:</strong> {userProfile.height} cm</Text>
            <Text><strong>Goal:</strong> {formatGoal(userProfile.goal)}</Text>
            <Text><strong>Dietary Preferences:</strong> {userProfile.dietaryPreferences?.length > 0 ? userProfile.dietaryPreferences.join(', ') : 'None specified'}</Text>
            <Text fontSize="sm" color="gray.500" mt={2}>This information is read-only and used for nutritional guidance generation.</Text>
          </Box>
        )}
        <Box>
          <MealSuggestions
            userProfile={{
              age: userProfile?.age,
              gender: userProfile?.gender,
              weight: userProfile?.weight,
              height: userProfile?.height,
              goal: userProfile?.goal,
              dietaryPreferences: userProfile?.dietaryPreferences,
              fitnessLevel: userProfile?.fitnessLevel
            }}
          />
        </Box>
        <Box w="full" p={5} shadow="md" borderWidth="1px">
          <Heading size="md" mb={4}>Your Meal Plans</Heading>
          {mealPlans.length > 0 ? (
            <Accordion allowMultiple>
              {mealPlans.map((plan, index) => (
                <AccordionItem key={index}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="bold">{plan.mealPlanName}</Text>
                        <Text fontSize="sm">Created: {new Date(plan.dateCreated).toLocaleString()}</Text>
                        <Text fontSize="sm">Goal: {plan.goal}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Meal Name</Th>
                          <Th>Calories</Th>
                          <Th>Protein (g)</Th>
                          <Th>Carbs (g)</Th>
                          <Th>Fat (g)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {plan.meals.map((meal, mealIndex) => (
                          <Tr key={mealIndex}>
                            <Td>{meal.mealName}</Td>
                            <Td>{meal.calories}</Td>
                            <Td>{meal.protein}</Td>
                            <Td>{meal.carbohydrates}</Td>
                            <Td>{meal.fats}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          ) : (
            <Text>No meal plans found. Generate meal plans to see them here!</Text>
          )}
        </Box>
      </VStack>
    </Container>
  );
};

export default NutritionalGuidance;